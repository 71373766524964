/*
 * Flexible validation service to
 * validate according to RegExps and REST API.
 *
 */
import {getLoadedApplicationUtils} from '../utils/common-utils.js';

(function () {
  function ValidationService(schema) {
    getLoadedApplicationUtils().invariant(
      schema,
      'Need schema to initialize Validation Service'
    );

    getLoadedApplicationUtils().invariant(
      typeof schema === 'object' && Object.keys(schema).length,
      'Need schema to be an object with keys'
    );

    this._schema = schema;
  }

  ValidationService.prototype = {
    _prepareDataToSendRequest: function (value, type, additionalData) {
      const data = {};

      data[type] = value;

      return $.extend({}, data, additionalData);
    },

    _validateDataWithAPI: function (url, data) {
      return $.ajax({
        url: url,
        data: data,
      });
    },

    _validateDataWithRegExp: function (value, type) {
      const isValid = !!this._schema[type] && this._schema[type].test(value);

      return $.Deferred().resolve({valid: isValid});
    },

    _isURL: function (value) {
      return /^http/.test(value);
    },

    validate: function (value, type, additionalData) {
      getLoadedApplicationUtils().invariant(
        value != null,
        'value argument is required'
      );
      getLoadedApplicationUtils().invariant(
        type != null,
        'type argument is required'
      );
      getLoadedApplicationUtils().invariant(
        typeof type === 'string',
        'type argument should be a string'
      );
      getLoadedApplicationUtils().invariant(
        additionalData === undefined ||
          (typeof additionalData === 'object' &&
            Object.keys(additionalData).length),
        'additionalData argument should be an object with data'
      );

      return this._isURL(this._schema[type])
        ? this._validateDataWithAPI(
            this._schema[type],
            this._prepareDataToSendRequest(value, type, additionalData)
          )
        : this._validateDataWithRegExp(value, type);
    },
  };

  app.ValidationService = ValidationService;
})();
