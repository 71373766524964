// User API module

import {getLoadedApplicationUtils} from '../utils/common-utils.js';

app.modules.userAPI = (function (self) {
  function _prepareDataToSignUser(signType, data) {
    const formData = new FormData(),
      KEY_NAME = signType === 'in' ? 'session' : 'user';

    data.withSID &&
      $.extend(data, {sid: getLoadedApplicationUtils().generateUUID()});
    data.type && formData.append('type', data.type);
    const smartCaptchaTokenForAuthComponent =
      app.config.smartCaptchaTokenForAuthComponent;
    if (smartCaptchaTokenForAuthComponent) {
      formData.append('captcha_required', true);
      formData.append('user_token', smartCaptchaTokenForAuthComponent);
    }

    (function _prepare(data, key) {
      Object.keys(data).forEach(function (item) {
        if (
          item === 'type' ||
          item === 'response_token' ||
          item === 'withSID'
        ) {
          return;
        }

        var newKey = key + '[' + item + ']';

        if (typeof data[item] === 'object') {
          _prepare(data[item], newKey);
        } else {
          formData.append(newKey, data[item]);
        }
      });
    })(data, KEY_NAME);

    data['response_token'] &&
      formData.append('response_token', data['response_token']);
    // For testing purposes
    formData.__test__ = data;

    return formData;
  }

  function _prepareURL(url, replaceHelper) {
    const placeholderToReplace = Object.keys(replaceHelper)[0];

    return url.replace(
      '_' + placeholderToReplace + '_',
      replaceHelper[placeholderToReplace]
    );
  }

  function _getURLToSign(signType) {
    return signType === 'in'
      ? app.config.usersSessionURL
      : app.config.usersRegistrationURL;
  }

  function _signUser(signType, data) {
    return $.ajax({
      url: _getURLToSign(signType),
      method: 'POST',
      data: _prepareDataToSignUser(signType, data),
      processData: false,
      contentType: false,
    });
  }

  function _mergeUsers(data) {
    return $.ajax({
      url: app.config.api.mergesURL,
      method: 'POST',
      data: data,
    });
  }

  function _updateUser(userID, data) {
    return $.ajax({
      url: app.config.api.updateUserURL.replace('_id_', userID),
      method: 'PUT',
      data: data,
    });
  }

  function _deleteSocialProvider(providerName) {
    return $.ajax({
      url: _prepareURL(app.config.api.userSocialProviderURL, {
        provider: providerName,
      }),
      method: 'DELETE',
    });
  }

  function _listenSocialRegistrationProcess(url) {
    getLoadedApplicationUtils().invariant(
      typeof url === 'string',
      'url should be a string, instead %s was passed',
      url
    );

    return getLoadedApplicationUtils().listenProcess({url: url});
  }

  function _signIn(data) {
    getLoadedApplicationUtils().validateOnObjectType(data);

    return _signUser('in', data);
  }

  function _signUp(data) {
    getLoadedApplicationUtils().validateOnObjectType(data);

    return _signUser('up', data);
  }

  return $.extend(self, {
    deleteSocialProvider: _deleteSocialProvider,
    listenSocialRegistrationProcess: _listenSocialRegistrationProcess,
    signIn: _signIn,
    signUp: _signUp,
    updateUser: _updateUser,
    mergeUsers: _mergeUsers,
  });
})(app.modules.userAPI || {});
